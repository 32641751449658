<template>
    <main>
        <!-- Modal -->
        <modalNotasList :notas="notas" :usuario="usuario" :idUsuario="idUsuario" ref="listNotas" v-on:ultima-nota="cargarUltimaNota()"></modalNotasList>  
        <!-- Modal -->
        <div class="modal fade" id="modalNotas" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">Notas de Enfermeria</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
                            <div class="card-header bg-light text-default ">
                                Examen Fisico
                            </div>
                            <div class="card-body">
                                <div class="form-group row">
                                    <label  class="col-sm-6 col-form-label"><i class="fa fa-stethoscope"></i> P.A.SISTOL / P.A.DIASTOL <span class="text-danger">(mmHg)</span> </label>
                                    <div class="col-sm-6 ">
                                        <div class="row">
                                            <div class="col-sm ">
                                                <input type="number" v-model="pa_sistolica" class="form-control" :class="{'is-invalid':$v.pa_sistolica.$error && $v.pa_sistolica.$dirty}">
                                            </div>
                                            <div class="col-sm-1 ">
                                                <h1>/</h1>
                                            </div>
                                            <div class="col-sm ">
                                                <input type="number" v-model="pa_diastolica" class="form-control" :class="{'is-invalid':$v.pa_diastolica.$error && $v.pa_diastolica.$dirty}">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label  class="col-sm-6 col-form-label"><i class="fa fa-history"></i> PULSO <span class="text-danger">(lat/min)</span></label>
                                    <div class="col-sm-6">
                                        <input type="number" v-model="pulso" class="form-control" :class="{'is-invalid':$v.pulso.$error && $v.pulso.$dirty}">
                                    </div>
                                </div>

                                <div class="form-group row">
                                    <label  class="col-sm-6 col-form-label"><i class="fa fa-thermometer"></i> TEMPERATURA <span class="text-danger">(ºC)</span></label>
                                    <div class="col-sm-6">
                                        <input type="number" v-model="temperatura" class="form-control" :class="{'is-invalid':$v.temperatura.$error && $v.temperatura.$dirty}">
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label  class="col-sm-6 col-form-label"><i class="fa fa-lungs"></i> RESPIRACION <span class="text-danger">(r/m)</span></label>
                                    <div class="col-sm-6">
                                        <input type="number" v-model="respiracion" class="form-control" :class="{'is-invalid':$v.respiracion.$error && $v.respiracion.$dirty}" >
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label  class="col-sm-6 col-form-label"><i class="fa fa-weight"></i> PESO <span class="text-danger">(KG)</span></label>
                                    <div class="col-sm-6">
                                        <input type="number" v-model="peso" class="form-control" :class="{'is-invalid':$v.peso.$error && $v.peso.$dirty}">
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label  class="col-sm-6 col-form-label"><i class="fa fa-ruler-vertical"></i> TALLA <span class="text-danger">(CM)</span></label>
                                    <div class="col-sm-6">
                                        <input type="number" v-model="talla" class="form-control" :class="{'is-invalid':$v.talla.$error && $v.talla.$dirty}">
                                    </div>
                                </div>
                            </div>
                            <div class="card-header bg-light text-default ">
                                Revisión por sistemas
                            </div>
                            <div class="card-body">
                                <div class="form-group row" v-for="item in partes_revision" :key="item.id">
                                    <label  class="col-sm-6 col-form-label"> <li>{{item.nombre}}</li> </label>
                                    <div class="col-sm-6 p-2">
                                        <div class="form-check form-check-inline">
                                            <input
                                                class="form-check-input"
                                                type="radio"
                                                :id="item.id"
                                                value="Normal"
                                                v-model="item.respuesta"
                                            />
                                            <label class="form-check-label">Normal</label>
                                        </div>
                                        <div class="form-check form-check-inline">
                                            <input
                                                class="form-check-input"
                                                type="radio"
                                                :id="item.id"
                                                value="Anormal"
                                                v-model="item.respuesta"
                                            />
                                            <label class="form-check-label">Anormal</label>
                                        </div>
                                    </div>
                                </div>
                                <span class="text-danger d-block" v-if="$v.partes_revision.$error && $v.partes_revision.$dirty">Debe completar la revision por sistemas</span>
                            </div>
                            <div class="form-group row">
                                    <label  class="col-sm-6 col-form-label"> Nota enfermeria </label>
                                    <div class="col-sm-12">
                                        <textarea cols="30" rows="3" class="form-control" v-model="descripcion" :class="{'is-invalid':$v.descripcion.$error && $v.descripcion.$dirty}"></textarea>
                                    </div>
                                </div>
                        </div> 
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-success btn-sm" @click="guardar();">Guardar<i class="fa fa-save fa-fw"></i></button>
                        <button type="button" class="btn btn-light btn-sm" data-dismiss="modal">Cerrar<i class="fa fa-times-circle fa-fw"></i></button>
                    </div>
                </div>
            </div>
        </div>
        <div class="card-header">
            <div class="row">
          <div class="col-6 ">
            Notas Enfermeria 
          </div>
          <div class="col-6 d-flex justify-content-end">
            <button  class="btn btn-primary  btn-xs" type="button" data-toggle="modal" data-target="#modalNotas">
                Nuevo &nbsp;<i class="fas fa-plus"></i>
            </button>           
          </div>
        </div>
        </div>
        <div class="card-body">
             <span class="d-block small" >Ultima Nota : <span v-if="Object.keys(ultima_nota).length">{{ultima_nota.fecha}}</span><span v-else class="text-danger">Sin datos...</span> </span>
            <button @click="historialNotas();" type="button" class="btn btn-info  btn-xs">Ver Notas <i class="far fa-eye fa-fw"></i></button>                
        </div>
    </main>
</template>
<script>

import notasEnfermeriaService from '../../../../services/notasEnfermeriaService';
import $ from 'jquery';
import Toast from '../../../../components/common/utilities/toast';
import {required} from "vuelidate/lib/validators";
import modalNotasList from "./modalNotasList";
import partesRevisionSistemasService from "../../../../services/partesRevisionSistemasService";

export default {
    props: ['idUsuario', 'usuario'],
    components:{modalNotasList},
    data(){
        return {
            notas:{},
            ultima_nota:{},
            id_usuario: '',
            peso:'',
            talla:'',
            pa_sistolica:'',
            pa_diastolica:'',
            respiracion:'',
            pulso:'',
            temperatura:'',
            descripcion:'',
            partes_revision:[],
            revision:{
                respuesta:''
            },
        }
    },
    validations (){
        return {
            peso:{required},
            talla:{required},
            pa_sistolica:{required},
            pa_diastolica:{required},
            respiracion:{required},
            pulso:{required},
            temperatura:{required},
            descripcion: {required},
            partes_revision : {
                $each : {
                    respuesta : {required}
                }
            }
        }
    },
    methods: {
        async cargarPartesRevision() {
            const response = await partesRevisionSistemasService.index({
                sexo : this.usuario.sexo
            });
            this.partes_revision = response.data.map(x => {
                return {
                    ...x,
                    respuesta: ''
                }
            });
        },
        async cargarUltimaNota(id){
            const response = await notasEnfermeriaService.showByLast(id);
            this.ultima_nota= response.data;
        },
        async historialNotas(){
            $('#modalNotasList').modal('show');
            this.$refs.listNotas.getNotas();
        },
        async guardar() {
            try {     

                this.$v.$touch();

                if(this.$v.$invalid) return;

                let sistemas= this.partes_revision.map(x => {
                    return {
                        id:x.id,
                        nombre:x.nombre,
                        respuesta:x.respuesta,
                    }
                });

                let notas ={
                    id_usuario:this.idUsuario,
                    peso:this.peso,
                    talla:this.talla,
                    pa_sistolica:this.pa_sistolica,
                    pa_diastolica:this.pa_diastolica,
                    respiracion:this.respiracion,
                    pulso:this.pulso,
                    temperatura:this.temperatura,
                    descripcion:this.descripcion,
                    revision_sistemas:sistemas,
                }

                
                this.LoaderSpinnerShow();
                await notasEnfermeriaService.store(notas);
                this.LoaderSpinnerHide();
                this.limpiarCampos();
                //$('.modal-backdrop').remove();
                //$("#modalNotas").destroy();
                $(`#modalNotas`).modal('hide');
                this.cargarUltimaNota(this.idUsuario);
                //$('body').removeClass('modal-open');
                
                Toast.fire({
                    icon: 'success',
                    title: 'Datos guardados con exito'
                });
                
               
               
            } catch (error) {
                this.LoaderSpinnerHide();
                Toast.fire({
                    icon: 'error',
                    title: 'Ocurrio un error al procesar la solicitud'
                });
            } 
        },
        limpiarCampos(){
            this.peso='';
            this.talla='';
            this.pa_sistolica='';
            this.pa_diastolica='';
            this.respiracion='';
            this.pulso='';
            this.temperatura='';
            this.descripcion='';
            this.partes_revision.forEach((item, i) => {
                this.partes_revision[i].respuesta = '';
            });
        },
    },
    watch: {
        idUsuario:function(){
            this.cargarUltimaNota(this.idUsuario);
        }
    },
    created() {
        //this.cargarUltimaNota(this.idUsuario);
        this.cargarPartesRevision();
    }
}
</script>