<template>
    <main>
        <div class="row">
            <div class="form-group col-lg-3 col-md-6 col-sm-12">
                <label class="form-label">No. Gestaciones</label>
                <input type="number" class="form-control form-control-sm" 
                v-model="antecedente_ob.no_gestaciones"
                :class="{'is-invalid':$v.antecedente_ob.no_gestaciones.$error && $v.antecedente_ob.no_gestaciones.$dirty}">
                <span class="text-danger text-sm" v-if="!$v.antecedente_ob.no_gestaciones.maxLengthValue">Debe ingresar un valor de máximo 2 digitos</span>
            </div>
            <div class="form-group col-lg-3 col-md-6 col-sm-12">
                <label class="form-label">No. Partos</label>
                <input type="number" class="form-control form-control-sm" v-model="antecedente_ob.no_partos"
                :disabled="isNumberFieldsDisabled"
                :class="{'is-invalid':$v.antecedente_ob.no_partos.$error && $v.antecedente_ob.no_partos.$dirty}">
                <span class="text-danger text-sm" v-if="!$v.antecedente_ob.no_partos.maxLengthValue">Debe ingresar un valor de máximo 2 digitos</span>
            </div>
            <div class="form-group col-lg-3 col-md-6 col-sm-12">
                <label class="form-label">No. Cesáreas</label>
                <input type="number" class="form-control form-control-sm" 
                v-model="antecedente_ob.no_cesareas"
                :disabled="isNumberFieldsDisabled"
                :class="{'is-invalid':$v.antecedente_ob.no_cesareas.$error && $v.antecedente_ob.no_cesareas.$dirty}">
                <span class="text-danger text-sm" v-if="!$v.antecedente_ob.no_cesareas.maxLengthValue">Debe ingresar un valor de máximo 2 digitos</span>
            </div>
            <div class="form-group col-lg-3 col-md-6 col-sm-12">
                <label class="form-label">No. Abortos</label>
                <input type="number" class="form-control form-control-sm" 
                v-model="antecedente_ob.no_abortos"
                :disabled="isNumberFieldsDisabled"
                :class="{'is-invalid':$v.antecedente_ob.no_abortos.$error && $v.antecedente_ob.no_abortos.$dirty}">
                <span class="text-danger text-sm" v-if="!$v.antecedente_ob.no_abortos.maxLengthValue">Debe ingresar un valor de máximo 2 digitos</span>
            </div>
            <div class="form-group col-lg-3 col-md-6 col-sm-12">
                <label class="form-label">No. Hijos vivos</label>
                <input type="number" class="form-control form-control-sm" 
                v-model="antecedente_ob.no_hijos_vivos"
                :disabled="isNumberFieldsDisabled"
                :class="{'is-invalid':$v.antecedente_ob.no_hijos_vivos.$error && $v.antecedente_ob.no_hijos_vivos.$dirty}">
                <span class="text-danger text-sm" v-if="!$v.antecedente_ob.no_hijos_vivos.maxLengthValue">Debe ingresar un valor de máximo 2 digitos</span>
            </div>
            <div class="form-group col-lg-3 col-md-6 col-sm-12">
                <label class="form-label">No. Hijos muertos</label>
                <input type="number" class="form-control form-control-sm" 
                v-model="antecedente_ob.no_hijos_muertos"
                :disabled="isNumberFieldsDisabled"
                :class="{'is-invalid':$v.antecedente_ob.no_hijos_muertos.$error && $v.antecedente_ob.no_hijos_muertos.$dirty}">
                <span class="text-danger text-sm" v-if="!$v.antecedente_ob.no_hijos_muertos.maxLengthValue">Debe ingresar un valor de máximo 2 digitos</span>
            </div>
            <div class="form-group col-lg-3 col-md-6 col-sm-12">
                <label class="form-label">No. Embarazos ectopicos</label>
                <input type="number" class="form-control form-control-sm" 
                v-model="antecedente_ob.no_embarazos_ectopicos"
                :disabled="isNumberFieldsDisabled"
                :class="{'is-invalid':$v.antecedente_ob.no_embarazos_ectopicos.$error && $v.antecedente_ob.no_embarazos_ectopicos.$dirty}">
                <span class="text-danger text-sm" v-if="!$v.antecedente_ob.no_embarazos_ectopicos.maxLengthValue">Debe ingresar un valor de máximo 2 digitos</span>
            </div>
            <div class="form-group col-lg-3 col-md-6 col-sm-12">
                <label class="form-label">No. Molas</label>
                <input type="number" class="form-control form-control-sm" 
                v-model="antecedente_ob.no_molas"
                :disabled="isNumberFieldsDisabled"
                :class="{'is-invalid':$v.antecedente_ob.no_molas.$error && $v.antecedente_ob.no_molas.$dirty}">
                <span class="text-danger text-sm" v-if="!$v.antecedente_ob.no_molas.maxLengthValue">Debe ingresar un valor de máximo 2 digitos</span>
            </div>
            <div class="form-group col-lg-3 col-md-6 col-sm-12">
                <label class="form-label">Fecha ultimo parto</label>
                <input type="date" class="form-control form-control-sm" 
                v-model="antecedente_ob.fecha_ultimo_parto"
                :disabled="isNumberFieldsDisabled"
                >
            </div>
        </div>
        <div class="row mt-2">
            <div class="list-group mb-4 col-lg-4 col-md-12 col-sm-12">
                <div class="list-group-item">
                    <div class="d-flex align-items-center justify-content-between">
                        <buttonsCheck :label="'Placenta previa'" 
                            :disabled="isNumberFieldsDisabled"
                            v-model="antecedente_ob.placenta_previa"
                            :validator="$v.antecedente_ob.placenta_previa"
                            >
                        </buttonsCheck>
                    </div>
                </div>
                <div class="list-group-item">
                    <div class="d-flex align-items-center justify-content-between">
                        <buttonsCheck :label="'RPM'"
                            v-model="antecedente_ob.rpm"
                            :validator="$v.antecedente_ob.rpm"
                            :disabled="isNumberFieldsDisabled">
                        </buttonsCheck>
                    </div>
                </div>
                <div class="list-group-item">
                    <div class="d-flex align-items-center justify-content-between">
                        <buttonsCheck :label="'RCIU'"
                            v-model="antecedente_ob.rciu"
                            :validator="$v.antecedente_ob.rciu"
                            :disabled="isNumberFieldsDisabled">
                        </buttonsCheck>
                    </div>
                </div>
                <div class="list-group-item">
                    <div class="d-flex align-items-center justify-content-between">
                        <buttonsCheck :label="'Embarazo prematuro'"
                            v-model="antecedente_ob.embarazo_prematuro"
                            :validator="$v.antecedente_ob.embarazo_prematuro"
                            :disabled="isNumberFieldsDisabled">
                        </buttonsCheck>
                    </div>
                </div>
                <div class="list-group-item">
                    <div class="d-flex align-items-center justify-content-between">
                        <buttonsCheck :label="'Transtornos hipertensivos del embarazo'"
                            v-model="antecedente_ob.transtornos_hipertensivos"
                            :validator="$v.antecedente_ob.transtornos_hipertensivos"
                            :disabled="isNumberFieldsDisabled">
                        </buttonsCheck>
                    </div>
                </div>
                <div class="list-group-item">
                    <div class="d-flex align-items-center justify-content-between">
                        <buttonsCheck :label="'Malformaciones congenitas'"
                            v-model="antecedente_ob.malformaciones_congenitas"
                            :validator="$v.antecedente_ob.malformaciones_congenitas"
                            :disabled="isNumberFieldsDisabled">
                        </buttonsCheck>
                    </div>
                </div>
            </div>

            <div class="list-group mb-4 col-lg-4 col-md-12 col-sm-12">
                <div class="list-group-item">
                    <div class="d-flex align-items-center justify-content-between">
                        <buttonsCheck :label="'Intervalo intergenesico'"
                            :disabled="isNumberFieldsDisabled"
                            v-model="antecedente_ob.intervalo"
                            :validator="$v.antecedente_ob.intervalo">
                        </buttonsCheck>
                    </div>
                </div>
                <div class="list-group-item">
                    <div class="d-flex align-items-center justify-content-between">
                        <buttonsCheck :label="'Polihidramnios'"
                            v-model="antecedente_ob.polihidramnios"
                            :validator="$v.antecedente_ob.polihidramnios"
                            :disabled="isNumberFieldsDisabled">
                        </buttonsCheck>
                    </div>
                </div>
                <div class="list-group-item">
                    <div class="d-flex align-items-center justify-content-between">
                        <buttonsCheck :label="'Embarazo gemelar'"
                            v-model="antecedente_ob.embarazo_gemelar"
                            :validator="$v.antecedente_ob.embarazo_gemelar"
                            :disabled="isNumberFieldsDisabled">
                        </buttonsCheck>
                    </div>
                </div>
                <div class="list-group-item">
                    <div class="d-flex align-items-center justify-content-between">
                        <buttonsCheck :label="'Retención de placenta'"
                            v-model="antecedente_ob.retencion_placenta"
                            :validator="$v.antecedente_ob.retencion_placenta"
                            :disabled="isNumberFieldsDisabled">
                        </buttonsCheck>
                    </div>
                </div>
                <div class="list-group-item">
                    <div class="d-flex align-items-center justify-content-between">
                        <buttonsCheck :label="'Hemorragia obstetrica'"
                            v-model="antecedente_ob.hemorragia_obstetrica"
                            :validator="$v.antecedente_ob.hemorragia_obstetrica"
                            :disabled="isNumberFieldsDisabled">
                        </buttonsCheck>
                    </div>
                </div>
                <div class="list-group-item">
                    <div class="d-flex align-items-center justify-content-between">
                        <buttonsCheck :label="'Otros'"
                            v-model="antecedente_ob.otros"
                            :validator="$v.antecedente_ob.otros"
                            :disabled="isNumberFieldsDisabled">
                        </buttonsCheck>
                    </div>
                </div>
            </div>
            
            <div class="list-group mb-4 col-lg-4 col-md-12 col-sm-12">
                <div class="list-group-item">
                    <div class="d-flex align-items-center justify-content-between">
                        <buttonsCheck :label="'Abruptio placenta'"
                            v-model="antecedente_ob.abruptio_placenta"
                            :validator="$v.antecedente_ob.abruptio_placenta"
                            :disabled="isNumberFieldsDisabled">
                        </buttonsCheck>
                    </div>
                </div>
                <div class="list-group-item">
                    <div class="d-flex align-items-center justify-content-between">
                        <buttonsCheck :label="'Oligohidramnios'"
                            v-model="antecedente_ob.oligohidramnios"
                            :validator="$v.antecedente_ob.oligohidramnios"
                            :disabled="isNumberFieldsDisabled">
                        </buttonsCheck>
                    </div>
                </div>
                <div class="list-group-item">
                    <div class="d-flex align-items-center justify-content-between">
                        <buttonsCheck :label="'Embarazo prolongado'"
                            v-model="antecedente_ob.embarazo_prolongado"
                            :validator="$v.antecedente_ob.embarazo_prolongado"
                            :disabled="isNumberFieldsDisabled">
                        </buttonsCheck>
                    </div>
                </div>
                <div class="list-group-item">
                    <div class="d-flex align-items-center justify-content-between">
                        <buttonsCheck :label="'Infecciones en el posparto'"
                            v-model="antecedente_ob.infecciones_posparto"
                            :validator="$v.antecedente_ob.infecciones_posparto"
                            :disabled="isNumberFieldsDisabled">
                        </buttonsCheck>
                    </div>
                </div>
                <div class="list-group-item">
                    <div class="d-flex align-items-center justify-content-between">
                        <buttonsCheck :label="'Bajo peso al nacer'"
                            v-model="antecedente_ob.bajo_peso"
                            :validator="$v.antecedente_ob.bajo_peso"
                            :disabled="isNumberFieldsDisabled">
                        </buttonsCheck>
                    </div>
                </div>
            </div>
            
        </div>
    </main>
</template>
<script>
import buttonsCheck from './buttonsCheckComponent';
import {required,maxLength,minValue} from "vuelidate/lib/validators";
export default {
    props:['ultimo_embarazo'],
    components: {buttonsCheck},
    data() {
        return {
            antecedente_ob: {
                no_gestaciones: 0,
                no_partos: 0,
                no_cesareas: 0,
                no_abortos: 0,
                no_hijos_vivos: 0,
                no_hijos_muertos: 0,
                no_embarazos_ectopicos: 0,
                no_molas: 0,
                fecha_ultimo_parto: '',
                placenta_previa:'',
                abruptio_placenta: '',
                rpm: '',
                polihidramnios: '',
                oligohidramnios: '',
                rciu: '',
                embarazo_gemelar: '',
                embarazo_prolongado: '',
                embarazo_prematuro: '',
                retencion_placenta: '',
                infecciones_posparto: '',
                transtornos_hipertensivos: '',
                hemorragia_obstetrica: '',
                bajo_peso: '',
                malformaciones_congenitas: '',
                otros: '',
                intervalo: '',
                id_antecedente_ob: '',
            },
            isNumberFieldsDisabled: true,
            type:'store'
        }
    },
    validations(){
        return {
            antecedente_ob:{
                no_gestaciones: {
                    minValueValue: minValue(0),
                    maxLengthValue: maxLength(2) ,
                    required
                },
                no_partos: {
                    minValueValue: minValue(0),
                    maxLengthValue: maxLength(2) ,
                    required
                },
                no_cesareas: {
                    minValueValue: minValue(0),
                    maxLengthValue: maxLength(2) ,
                    required
                },
                no_abortos: {
                    minValueValue: minValue(0),
                    maxLengthValue: maxLength(2) ,
                    required
                },
                no_hijos_vivos: {
                    minValueValue: minValue(0),
                    maxLengthValue: maxLength(2) ,
                    required
                },
                no_hijos_muertos: {
                    minValueValue: minValue(0),
                    maxLengthValue: maxLength(2) ,
                    required
                },
                no_embarazos_ectopicos: {
                    minValueValue: minValue(0),
                    maxLengthValue: maxLength(2) ,
                    required
                },
                no_molas: {
                    minValueValue: minValue(0),
                    maxLengthValue: maxLength(2) ,
                    required
                },
                placenta_previa:{required},
                abruptio_placenta: {required},
                rpm: {required},
                polihidramnios: {required},
                oligohidramnios: {required},
                rciu: {required},
                embarazo_gemelar: {required},
                embarazo_prolongado: {required},
                embarazo_prematuro: {required},
                retencion_placenta: {required},
                infecciones_posparto: {required},
                transtornos_hipertensivos: {required},
                hemorragia_obstetrica: {required},
                bajo_peso: {required},
                malformaciones_congenitas: {required},
                otros: {required},
                intervalo: {required}
            }
        }
    },
    methods: {
        retornar() {
            this.$v.antecedente_ob.$touch();
            if(this.$v.antecedente_ob.$invalid){
                return false;
            }else{
                return this.antecedente_ob;
            }
        },
        limpiar(){
            this.antecedente_ob.no_gestaciones= 0;
            this.antecedente_ob.no_partos= 0;
            this.antecedente_ob.no_cesareas= 0;
            this.antecedente_ob.no_abortos= 0;
            this.antecedente_ob.no_hijos_vivos= 0;
            this.antecedente_ob.no_hijos_muertos= 0;
            this.antecedente_ob.no_embarazos_ectopicos= 0;
            this.antecedente_ob.no_molas= 0;
            this.antecedente_ob.fecha_ultimo_parto= '';
            this.antecedente_ob.placenta_previa='';
            this.antecedente_ob.abruptio_placenta= '';
            this.antecedente_ob.rpm= '';
            this.antecedente_ob.polihidramnios= '';
            this.antecedente_ob.oligohidramnios= '';
            this.antecedente_ob.rciu= '';
            this.antecedente_ob.embarazo_gemelar= '';
            this.antecedente_ob.embarazo_prolongado= '';
            this.antecedente_ob.embarazo_prematuro= '';
            this.antecedente_ob.retencion_placenta= '';
            this.antecedente_ob.infecciones_posparto= '';
            this.antecedente_ob.transtornos_hipertensivos= '';
            this.antecedente_ob.hemorragia_obstetrica= '';
            this.antecedente_ob.bajo_peso= '';
            this.antecedente_ob.malformaciones_congenitas= '';
            this.antecedente_ob.otros= '';
            this.antecedente_ob.intervalo= '';
        },
        show(){
            this.antecedente_ob.no_gestaciones=this.ultimo_embarazo.antecedentes_obstetricos.no_gestaciones;
            this.antecedente_ob.no_partos=this.ultimo_embarazo.antecedentes_obstetricos.no_partos;
            this.antecedente_ob.no_cesareas=this.ultimo_embarazo.antecedentes_obstetricos.no_cesareas;
            this.antecedente_ob.no_abortos=this.ultimo_embarazo.antecedentes_obstetricos.no_abortos;
            this.antecedente_ob.no_hijos_vivos=this.ultimo_embarazo.antecedentes_obstetricos.no_hijos_vivos;
            this.antecedente_ob.no_hijos_muertos=this.ultimo_embarazo.antecedentes_obstetricos.no_hijos_muertos;
            this.antecedente_ob.no_embarazos_ectopicos=this.ultimo_embarazo.antecedentes_obstetricos.no_embarazos_ectopicos;
            this.antecedente_ob.no_molas=this.ultimo_embarazo.antecedentes_obstetricos.no_molas;
            this.antecedente_ob.fecha_ultimo_parto=this.ultimo_embarazo.antecedentes_obstetricos.fecha_ultimo_parto;
            this.antecedente_ob.placenta_previa=this.ultimo_embarazo.antecedentes_obstetricos.placenta_previa;
            this.antecedente_ob.rpm=this.ultimo_embarazo.antecedentes_obstetricos.rpm;
            this.antecedente_ob.rciu=this.ultimo_embarazo.antecedentes_obstetricos.rciu;
            this.antecedente_ob.embarazo_prematuro=this.ultimo_embarazo.antecedentes_obstetricos.embarazo_prematuro;
            this.antecedente_ob.transtornos_hipertensivos=this.ultimo_embarazo.antecedentes_obstetricos.transtornos_hipertensivos;
            this.antecedente_ob.intervalo=this.ultimo_embarazo.antecedentes_obstetricos.intervalo_intergenesico;
            this.antecedente_ob.malformaciones_congenitas=this.ultimo_embarazo.antecedentes_obstetricos.malformaciones_congenitas;
            this.antecedente_ob.polihidramnios=this.ultimo_embarazo.antecedentes_obstetricos.polihidramnios;
            this.antecedente_ob.oligohidramnios=this.ultimo_embarazo.antecedentes_obstetricos.oligohidramnios;
            this.antecedente_ob.embarazo_gemelar=this.ultimo_embarazo.antecedentes_obstetricos.embarazo_gemelar;
            this.antecedente_ob.retencion_placenta=this.ultimo_embarazo.antecedentes_obstetricos.retencion_placenta;
            this.antecedente_ob.hemorragia_obstetrica=this.ultimo_embarazo.antecedentes_obstetricos.hemorragia_obstetrica;
            this.antecedente_ob.otros=this.ultimo_embarazo.antecedentes_obstetricos.otros;
            this.antecedente_ob.abruptio_placenta=this.ultimo_embarazo.antecedentes_obstetricos.abruptio_placenta;
            this.antecedente_ob.embarazo_prolongado=this.ultimo_embarazo.antecedentes_obstetricos.embarazo_prolongado;
            this.antecedente_ob.infecciones_posparto=this.ultimo_embarazo.antecedentes_obstetricos.infecciones_posparto;
            this.antecedente_ob.bajo_peso=this.ultimo_embarazo.antecedentes_obstetricos.bajo_peso;
            this.antecedente_ob.id_antecedente_ob=this.ultimo_embarazo.antecedentes_obstetricos.id;
        }
    },
    watch: {
        'antecedente_ob.no_gestaciones': function(newValue) {
            this.isNumberFieldsDisabled = !(newValue >= 2);
        },
    },
    
}
</script>