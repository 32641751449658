<template>
  <main>
    <!-- Modal -->
    <div class="modal fade" id="modalEmbarazo" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-xl modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Antecedentes Obstetricos</h5>
            <button type="button" class="close" @click="cerrar()" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="card shadow-none border-0">
              <div class="card-body">
                <div class="form-group ">
                  <label class="form-label">Fecha de día de atención<span class="text-danger">*</span></label>
                  <input type="date" class="form-control form-control-sm" v-model="fecha"
                  :class="{'is-invalid':$v.fecha.$error && $v.fecha.$dirty}">
                </div>
                <antecedenteObstetrico ref="antecedenteObstetrico" :ultimo_embarazo="ultimo_embarazo"></antecedenteObstetrico>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-success btn-sm" @click="guardar();"><i
                class="fa fa-save fa-fw"></i>Guardar</button>
            <button type="button" class="btn btn-light btn-sm" @click="cerrar()"><i
                class="fa fa-times-circle fa-fw"></i>Cerrar</button>
          </div>
        </div>
      </div>
    </div>
    <div class="card-header">
      <div class="row">
        <div class="col-6 ">
          Embarazo
        </div>
        <div class="col-6 d-flex justify-content-end">
          <button v-if="ultimo_embarazo.activo !== 'S'" class="btn btn-primary btn-xs" type="button" data-toggle="modal"
            data-target="#modalEmbarazo">
            Nuevo &nbsp;<i class="fas fa-plus"></i>
          </button>
          <div v-else>
            <button class="btn btn-success btn-xs mx-2" type="button" @click="finalizar">Finalizar</button>
            <button class="btn btn-info btn-xs" type="button" @click="editar">Ver</button>
          </div>
        </div>
      </div>
    </div>
    <div class="card-body">
      <span class="d-block small">Ultimo Embarazo :
        <span v-if="Object.keys(ultimo_embarazo).length">{{ ultimo_embarazo.fecha }}</span>
        <span v-else class="text-danger">Sin datos...</span>
      </span>
    </div>
  </main>
</template>
<script>

import embarazoService from '../../../../services/embarazoService';
import antecedenteObstetrico from "./antecedenteObstetrico";
import $ from 'jquery';
import Swal from "sweetalert2";
import {required} from "vuelidate/lib/validators";
export default {
  props: ['idUsuario', 'usuario'],
  components: { antecedenteObstetrico },
  data() {
    return {
      embarazo: {},
      fecha: '',
      ultimo_embarazo: {},
      id_usuario: '',
      antecedente_ob:''
    }
  },
  validations(){
    return {
      antecedente_ob:{required},
      fecha:{required},
    }
  },
  methods: {
    async cargarEmbarazo() {
      const response = embarazoService.showById(this.idUsuario);
      this.embarazo = response.data;
    },
    async cargarUltimoEmbarazo(id) {
      const response = await embarazoService.showByLast(id);
      this.ultimo_embarazo = response.data;
    },
    async guardar() {
      try {

        //let antecedente_ob = [];
        const antecedente_ob = this.$refs.antecedenteObstetrico.retornar();
        console.log(antecedente_ob);
        if(antecedente_ob){
          this.antecedente_ob = 'SI'; 
        }else{
          this.antecedente_ob="";
        }

        this.$v.$touch();
        if(this.$v.$invalid) return;

        let embarazo = {
          fecha: this.fecha,
          id_usuario: this.idUsuario,
          antecedentes: antecedente_ob
        };

        this.LoaderSpinnerShow();
        if(this.$refs.antecedenteObstetrico.type==='store'){
          await embarazoService.store(embarazo)
        }else{
          await embarazoService.update(embarazo)
        }
        
        this.LoaderSpinnerHide();
        this.$refs.antecedenteObstetrico.limpiar();
        $(`#modalEmbarazo`).modal('hide');
        this.cargarUltimoEmbarazo(this.idUsuario);
        Swal.fire('Datos guardados con exito', '', 'success');
      } catch (error) {
        console.error(error);
        this.LoaderSpinnerHide();
        Swal.fire('Ha ocurrido un error al procesar la solicitud', '', 'error');
      }


    },
    async finalizar() {
      const result = await Swal.fire({
        title: "¿Está seguro de finalizar el embarazo ?",
        text: "Al finalizar el embarazo no se podra continuar el control prenatal",
        showCancelButton: true,
        showConfirmButton: true,
        confirmButtonText: 'Sí, finalizar',
        cancelButtonText: 'No',
        icon: "warning",
        showLoaderOnConfirm: true,
        preConfirm: async () => {
          try {
            await embarazoService.close(this.ultimo_embarazo.id);
          } catch (e) {
            console.error(e);
            Swal.showValidationMessage('ha ocurrido un error al procesar la solicitud');
          }

        },
      });

      if (result.isConfirmed) {
        Swal.fire('Exíto', 'Embarazo Finalizado con Exíto', 'success');
        this.cargarUltimoEmbarazo(this.idUsuario);
      }
    },
    cerrar(){
      $('#modalEmbarazo').modal('hide');
      this.$refs.antecedenteObstetrico.limpiar();
    },
    editar(){
      $('#modalEmbarazo').modal('show');
      this.fecha=this.ultimo_embarazo.fecha;
      this.$refs.antecedenteObstetrico.show();
      this.$refs.antecedenteObstetrico.type='update';
    }
  },
  created() {
    this.cargarUltimoEmbarazo(this.idUsuario);
  },
  watch: {
    idUsuario: function () {
      this.cargarUltimoEmbarazo(this.idUsuario);
    }
  },
}
</script>