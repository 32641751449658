<template>
    <div class="form-group">
        <label class="form-label">{{label}}</label>
        <button @click="selectOption(i)" 
            v-for="(tipo, i) in tipo_opciones" 
            :data-tipo="`tipo_${tipo.id}`" 
            :key="`${i}_tipo`"
            :class="getButtonClasses(tipo)"
            :disabled="disabled">
                <i :class="tipo.icon"></i>
                {{ tipo.name }}
        </button>
        <div v-show="tipoSeleccionado && tipoSeleccionado.name==='SI'">
            <textarea 
                v-model="observacion"
                :class="classObject"
                :disabled="disabled"
                class="form-control w-100" 
                rows="2" 
                placeholder="Ingrese una observación">
            </textarea>
        </div>
    </div>
</template>
<script>
export default {
    props:['label','value',"validator","disabled"],
    data() {
        return {
            tipo_opciones: [
                {
                    id: 1,
                    name: 'SI',
                    icon:'fa fa-check fa-fw',
                    style:'primary'
                },
                {
                    id: 2,
                    name: 'NO',
                    icon:'fa fa-times fa-fw',
                    style:'danger'
                }
            ],
            tipoSeleccionado: '',
            observacion : this.value,
            defaultObservacion:'NO'
        }
    },
    methods: {
        selectOption(i) {
            const tipo = this.tipo_opciones[i];
            this.tipoSeleccionado = tipo;
            this.observacion = this.tipoSeleccionado.name === 'NO' ? 'NO' : '';
        },
        getButtonClasses(tipo) {
            return {
                'btn': true,
                'btn-outline-primary': tipo !== this.tipoSeleccionado && tipo.name === 'SI',
                'btn-outline-danger': tipo !== this.tipoSeleccionado && tipo.name === 'NO',
                'btn-primary': tipo === this.tipoSeleccionado,
                'btn-danger': tipo === this.tipoSeleccionado && tipo.name === 'NO',
                'btn-xs': true,
                'mx-1': true
            };
        },
    },
    watch: {
        value: async function (value) {
           
            if (value === "" || value === undefined || value === null) {
                this.observacion= "";
                return;
            }
            
            this.tipoSeleccionado = value !== 'NO' ? this.tipo_opciones[0] : this.tipo_opciones[1];
            this.observacion = value;
        },
        observacion : function (value) {
            this.$emit('input', value);
        }, 
        disabled: function(value) {
            if (value) {
                this.observacion = this.defaultObservacion;
            }
        }
    },
    computed: {
        classObject: function () {
            if (this.validator === undefined || this.validator === null) {
                return "";
            }
            return this.validator.$error ? "is-invalid" : "is-valid";
        },
    },
    created() {
        //this.observacion = this.value;
        if (this.disabled) {
            this.observacion = 'NO';
        }
    },
}
</script>