<template>
    <main>
        <div class="modal fade" id="modalPerfil" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
            <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel"><i class="fas fa-list fa-fw text-primary"></i> Tipos Historias Clinicas</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div v-show="isLoading">
                    <!-- Marcadores de posición mientras se cargan los datos -->
                    <content-placeholders>
                        <content-placeholders-heading :img="false" />
                        <content-placeholders-text :lines="3" />
                    </content-placeholders>
                </div>
                
                <div class="row text-center" v-show="!isLoading">
                    <div class="col-lg-4 col-sm-12 col-md-6 col-xs-12 mt-2" v-for="tipo in tipoHistoria" :key="tipo.id"> 
                        <a @click="nuevaHistoria(tipo.id);" class="custom-card">
                            <div class="card lift shadow-sm">
                                <div class="card-body">                     
                                    <h6 class="card-title">{{tipo.nombre}}</h6>
                                    <p class="card-text">({{tipo.edad_inicio}}{{tipo.tipo_edad_inicio}} - {{tipo.edad_final}}{{tipo.tipo_edad_final}})</p>
                                </div>
                            </div>
                        </a>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-light btn-sm" data-dismiss="modal">Cerrar<i class="fa fa-times-circle fa-fw"></i></button>
            </div>
            </div>
        </div>
        </div>
    </main>
</template>
<script>
import historiaClinicaService from '../../../services/historiaClinicaService';
import tipoHistoriaService from '../../../services/tipoHistoriaService';
import citasService from '../../../services/citasService';
import $ from 'jquery';
import 'bootstrap';
import {isEmpty} from 'lodash';
import Swal from 'sweetalert2';
import embarazoService from '../../../services/embarazoService';
export default {
    props:['usuario'],
    data () {
        return {
            historia : {
                id : '',
                fecha : '',
                id_usuario : '',
                id_tipo_historia:'',
                id_eps: '',
            },
            tipoHistoria : [],
            isLoading: true,
            ultimo_embarazo:{}
        }
    },
    methods : {
        async nuevaHistoria(id){
            let response="";
            const tipoHistoria = this.tipoHistoria.find(x => x.id === id);
            const result = await Swal.fire({
                title :  "¿Quiere iniciar una Historia de "+tipoHistoria.nombre+" ?",
                text : "Antes de proceder verifique que la edad del paciente corresponda a esta historia, recuerde que una vez iniciada la historia no se podrán realizar cambios ",
                showCancelButton : true,
                showConfirmButton : true,
                confirmButtonText : 'Sí, Iniciar',
                cancelButtonText : 'No',
                icon : "question",
                showLoaderOnConfirm : true,
                preConfirm: async () => {

                    var hoy = new Date();
                    var fechaAct = hoy.getFullYear() + '-' + ( hoy.getMonth() + 1 ) + '-' + hoy.getDate();
                                
                    if(tipoHistoria.codigo==='EMB'){
                        if (isEmpty(this.ultimo_embarazo)) {
                            Swal.showValidationMessage('Para iniciar una historia de control prenatal la paciente debe tener un embarazo activo, por favor registre un nuevo embarazo');
                            return
                        }else{
                            try{
                                this.LoaderSpinnerShow();
                                
                                this.historia.id_usuario = this.usuario.id;
                                this.historia.id_tipo_historia = id;
                                this.historia.fecha=fechaAct;
                                this.historia.id_eps=this.usuario.contratos?this.usuario.contratos[0].contrato.eps.id:''

                                response = await historiaClinicaService.store(this.historia);
                                this.LoaderSpinnerHide();
                            }catch (e) {
                                console.error(e);
                                this.LoaderSpinnerHide();
                                Swal.showValidationMessage('ha ocurrido un error al procesar la solicitud');
                            }
                        }       
                    }else{
                        try{
                            this.LoaderSpinnerShow();
                            this.historia.id_usuario = this.usuario.id;
                            this.historia.id_tipo_historia = id;
                            this.historia.fecha=fechaAct;
                            this.historia.id_eps=this.usuario.contratos?this.usuario.contratos[0].contrato.eps.id:''
                            response = await historiaClinicaService.store(this.historia);
                            this.LoaderSpinnerHide();
                        }catch (e) {
                            console.error(e);
                            this.LoaderSpinnerHide();
                            Swal.showValidationMessage('ha ocurrido un error al procesar la solicitud');
                        }
                    }
                },
            });

            if(result.isConfirmed){
                $('#modalPerfil').modal('hide');

                const tipoHistoria = this.tipoHistoria.find(x => x.id === id)

                this.$router.push({
                    name : tipoHistoria.path,
                    params : { 
                        id : response.data.id
                    }
                });

                if(!isEmpty(response.data)){
                    const response_last_cita=await citasService.last(this.usuario.id);
                    if(!isEmpty(response_last_cita.data)){
                        await citasService.atendida(response_last_cita.data.id);
                    }
                    //console.log(response_last_cita.data);
                }
            }

        },
        async nuevaHistoriaoLD(id){
            try{
                
                this.LoaderSpinnerShow();
                var hoy = new Date();
                
                var fechaAct = hoy.getFullYear() + '-' + ( hoy.getMonth() + 1 ) + '-' + hoy.getDate();
                
                this.historia.id_usuario = this.usuario.id;
                this.historia.id_tipo_historia = id;
                this.historia.fecha=fechaAct;

                const response = await historiaClinicaService.store(this.historia);
                
                $('#modalPerfil').modal('hide');

                const tipoHistoria = this.tipoHistoria.find(x => x.id === id)

                this.$router.push({
                    name : tipoHistoria.path,
                    params : { 
                        id : response.data.id
                    }
                });

                if(!isEmpty(response.data)){
                    const response_last_cita=await citasService.last(this.usuario.id);
                    if(!isEmpty(response_last_cita.data)){
                        await citasService.atendida(response_last_cita.data.id);
                    }
                    console.log(response_last_cita.data);
                }

                this.LoaderSpinnerHide();

            }catch(err){
                this.LoaderSpinnerHide();
                console.error(err);
            }
        },
        async cargarTipoHistoria(){
            try {
                this.isLoading=true;
                $("#modalPerfil").modal("show");
                const response= await tipoHistoriaService.index({
                    fecha_nacimiento :  this.usuario.fecha_nacimiento,
                    sexo : this.usuario.sexo,
                });
                this.tipoHistoria = response.data;
                await this.cargarUltimoEmbarazo(this.usuario.id);
                this.isLoading=false;
                    
            } catch (error) {
                console.log(error);
                this.isLoading=false;
                Swal.fire("Ups!", "ha ocurrido un error al procesar la solicitud", "error");
            }
           
        },
        async cargarUltimoEmbarazo(id) {
            const response = await embarazoService.showByLast(id);
            this.ultimo_embarazo = response.data;
        },
    },
    watch:{
        usuario:function(){
            if(!isEmpty(this.usuario)){
               this.cargarUltimoEmbarazo(this.usuario.id);
            }
        }
    }
}
</script>
<style scope>
a.custom-card,
a.custom-card:hover {
  
  cursor:pointer
}
</style>

