import Service from "./Service";
const baseurl = '/api/notas-enfermeria';
import {isEmpty} from 'lodash';
export default {

    async showByIdUser(search = '',pagination = {},id) {
        let url = `${baseurl}/show-by-idUser/${id}?`;

        url += search !== '' ? `s=${search}` : '';

        url += (!isEmpty(pagination))
            ? `&paginate=true&per_page=${pagination.per_page}&page=${pagination.page}`
            : '';

        return Service.get(url);
    },
    store(obj) {
        return Service.post(`${baseurl}/store`, obj);
    },
    show(id) {
        return Service.get(`${baseurl}/show/${id}`);
    },
    update(obj) {
        return Service.put(`${baseurl}/update`, obj);
    },
    delete(id) {
        return Service.delete(`${baseurl}/delete/${id}`);
    },
    showByLast(id) {
        return Service.get(`${baseurl}/show-by-last/${id}`);
    },
    print(id) {
        return `${process.env.VUE_APP_BASE_PATH}${baseurl}/print/${id}`
    },
}
