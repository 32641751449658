<template>
    <main>
        <div class="modal fade" id="modalRemisionesPerfil" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
                <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">Remisiones</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <ul class="nav nav-tabs" id="myTab" role="tablist">
                        <li class="nav-item" role="presentation">
                            <a class="nav-link active" id="home-tab" data-toggle="tab" href="#nuevo" role="tab" aria-controls="new-remision" aria-selected="true">Nueva Remisión</a>
                        </li>
                        <li class="nav-item" role="presentation">
                            <a class="nav-link" id="profile-tab" data-toggle="tab" href="#listado" role="tab" aria-controls="list-remision" aria-selected="false">Listado Remisiones</a>
                        </li>
                    </ul>
                    <div class="tab-content" id="myTabContent">
                        <div class="tab-pane fade show active" id="nuevo" role="tabpanel" aria-labelledby="nuevo-tab">
                            <create v-on:cargar-Remisiones="cargar" :idHistoria="idHistoria" :usuario="usuario"></create>
                        </div>
                        <div class="tab-pane fade" id="listado" role="tabpanel" aria-labelledby="listado-tab">
                            <list ref="listRemisiones"  :idHistoria="idHistoria"></list>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-light btn-sm" data-dismiss="modal"><i class="far fa-times-circle fa-fw"></i> Cerrar</button>
                </div>
                </div>
            </div>
        </div>
    </main>
       
    </template>
    <script>
    import create from './create';
    import list from './list';
    export default {
        components:{create,list},
        props: ['idHistoria','usuario'],
        methods: {
            async cargar(){
                this.$refs.listRemisiones.cargarRemisiones(this.idHistoria);
            }
        }
    }
    </script>
    
    